import styled, { css } from "styled-components";
import theme from "../../styled/Theme";
import { H3, H4, Text } from "../../styled/Typo";

export const RegisterHeader = styled.div`
  text-align: center;
  margin-bottom: 117px;
  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: 86px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: 86px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-top: 92px
  }


  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: 41px;
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 81px;
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-bottom: 117px;
  }

  h3 {
    margin-bottom: 0;
  }
`

export const RegisterWrapper = styled.div`
  position: relative;
  display: grid;
  padding: 1rem;

  @media (min-width: ${theme.breakpoints.sm}) {
    grid-template-columns: 1fr;
  }
  @media (min-width: ${theme.breakpoints.md}) {
    grid-template-columns: 1fr 3fr;

  }
  @media (min-width: ${theme.breakpoints.xl}) {
    grid-template-columns: 1fr 2fr 1fr;
  }
  max-width: 1595px;
  margin: auto;
  @media (min-width: ${theme.breakpoints.sm}) {
    margin-bottom: 72px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 48px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-bottom: 204px
  }
`
export const RegisterButtons = styled.div`
  @media (min-width: ${theme.breakpoints.md}) {
    position: relative
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 65px;
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 0;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-left: 0px;
    grid-column: span 2;
  }

  @media (min-width: ${theme.breakpoints.md}) {
    margin-left: 55px;
    grid-column: span 1;
  }

  @media (min-width: ${theme.breakpoints.xl}) {
    margin-left: 84px;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    margin-left: 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: 32px;
  }
`
export const RegisterButton = styled( H3 )`
  cursor: pointer;

  @media (max-width: 1400px) {
    margin-right: 20px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 32px;
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-bottom: 84px;
  }

  ${props => props.active === true && css`
    color: ${theme.color.primary};

  `}
  &:hover {
    color: ${theme.color.primary};
  }

`

export const RegisterContent = styled.div`
  position: relative;
  margin: auto;
  height: 100%;
  min-height: 300px;
  background-image: radial-gradient(circle at 50% 50%, #2A2929 0%, #12110C 45%);

  
  @media (min-width: ${theme.breakpoints.sm}) {
    display: flex;
    align-items: center;
  }

  @media (min-width: ${theme.breakpoints.md}) {
    display: block;
  }

  @media (min-width: ${theme.breakpoints.sm}) {padding: 0 32px}
  @media (min-width: ${theme.breakpoints.md}) {padding: 0 32px}
  @media (min-width: ${theme.breakpoints.xl}) {padding: 0 1rem}
  
  @media (min-width: ${theme.breakpoints.sm}) {
    margin: 0 1rem;
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin: auto;
  }
`
export const PointWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const LargeImage = styled.img`
  width: 100%;
  height: auto;
`
export const RegisterLinks = styled.div`
  @media (min-width: ${theme.breakpoints.sm}) {
    margin-right: 0px;
  }

  @media (min-width: ${theme.breakpoints.md}) {
    margin-right: 55px;
  }

  @media (min-width: ${theme.breakpoints.xl}) {
    margin-right: 84px;
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    width: 100%;
    display: flex;
    align-items: center;
    grid-column: span 2;
  }

  @media (min-width: ${theme.breakpoints.xl}) {
    width: auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    grid-column: span 1;
  }
  
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`

export const RegisterGallery = styled.div`

`


export const Point = styled( H4 )`
  color: ${theme.color.black};
  position: absolute;

  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.color.primary};
  border-radius: 100%;

  cursor: pointer;
  transition: .6s ease;

  &:hover {
    transform: scale(1.1);
  }

  ${props => props.active && css`
    background: ${theme.color.black};
    color: white;
    z-index: 1;

  `}

  ${props => props.x && css`
    left: ${props.x};
  `}

  ${props => props.y && css`
    top: ${props.y};
  `}
`

// {overline, headline, items, parent, image}
export const PopoverH4 = styled( H4 )`
  color: ${theme.color.black};
  margin-bottom: 12px;
`
export const PopoverText = styled( Text )`
  color: ${theme.color.black};
  line-height: 32px;
`
export const PdfLink = styled.a`
  color: white;
  line-height: 32px;
  margin-bottom: 15px;
  text-decoration: none;
  display: flex;
  align-items: baseline;

  &:hover {
    color: ${theme.color.primary};
  }

  svg {
    width: 1em;
    height: 1em;
    margin-right: 12px;
  }

`

