import * as React from "react"
import Footer from "../components/Footer/Footer";
import ContentSwitcher from "../components/ContentSwitcher/ContentSwitcher";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from "styled-components";
import theme from "../components/styled/Theme";

import './css/global.css'
import Navigation from "../components/Navigation/Navigation";
import { store } from "../store/store";
import { Provider } from "react-redux";
import { content } from "../data/content";
import { navigation } from "../data/navigation";
import { footer } from "../data/footer";
import Register from "../components/Product/Register/Register";
import { Helmet } from "react-helmet";
import ContactSticky from "../components/ContactSticky/ContactSticky";
import { useEffect } from "react";

const data = {
	title: {de: 'Mini-Serie', en: 'Mini-Series'},
	sticky: {
		headline: {
			de: 'Interessiert?',
			en: 'INTERESTED?',
			fr: 'INTÉRESSÉ?',
			it: 'INTERESSATO? ',
		},
		copy: {
			de: 'Schreiben Sie eine Nachricht!',
			en: 'CONTACT US!',
			fr: 'CONTACTEZ-NOUS !',
			it: 'CONTATTACI!',
		},
		link: {
			de: 'Zum Kontakt',
			en: 'TO CONTACT',
			it: 'Al contatto',
			fr: 'Vers le contact',
		},
	},
	navigation,
	content,
	footer
}


// markup
const IndexPage = ({pageContext}) => {

	useEffect(() => {
		store.dispatch({type: 'setLanguage', lang: pageContext.activeLanguage || 'de'})
	}, [])
	console.log(pageContext)
	// console.log(data
	return (
			<>
				<Helmet title={data.title['de']} defer={false} />

				<ThemeProvider theme={theme}>
					<Provider store={store}>
						<Navigation {...data.navigation}/>
						<ContactSticky {...data.sticky}/>
						<main>
							<ContentSwitcher content={data.content}/>
						</main>

						<Register />
						<Footer {...data.footer}/>
					</Provider>
				</ThemeProvider>
			</>
	)
}

export default IndexPage
