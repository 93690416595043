import styled from "styled-components";
import { Text } from "../styled/Typo";
import theme from "../styled/Theme";

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 8px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    padding: 16px 24px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    padding: 29px 40px
  }

  background: ${theme.color.black};
  @media (min-width: ${theme.breakpoints.xl}) {
    background: transparent;
    position: absolute;
  }
  top: 0;
  left: 0;
  right: 0;

  img {
    @media (min-width: ${theme.breakpoints.sm}) {
      width: 143px
    }
    @media (min-width: ${theme.breakpoints.md}) {
      width: 190px
    }
    @media (min-width: ${theme.breakpoints.xl}) {
      width: 286px
    }
    height: auto;

  }
`

export const NavList = styled.ul`
  display: none;
  @media (min-width: 1650px) {
    display: flex
  }

  list-style: none;
  margin: -15px;
`

export const NavItem = styled.li`
  padding: 15px;
`

export const NavLink = styled( Text )`
  color: white;
  text-decoration: none;
  font-weight: ${theme.font.weight.bold};
  font-size: 18px;
  letter-spacing: 0.56px;
  line-height: 24px;

  &:hover {
    color: ${theme.color.primary}
  }
`
export const LanguageStyled = styled.div`
  .dropdown {
    border: 0;
    background: none;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;

    &:hover {
      * {
        color: ${theme.color.primary};
      }
    }
  }

  .dropdown-toggle:after {
    display: none;
  }

  .dropdown-menu {
    padding: 0;
    border-radius: 0;
    background: rgba(255, 255, 255, .17);
    color: white;

    .dropdown-item {
      color: white;

      &:hover {
        background-color: ${theme.color.grey};
      }

      &:active {
        background-color: ${theme.color.grey};
      }
    }
  }

  .btn {
    background: none !important;
    box-shadow: none !important;
    border: 0 !important;
    display: flex;
    align-items: center;
  }

  .btn:active, .btn:focus {
    outline: 0;
    box-shadow: none !important;
    background: none;
    border: 0;
  }
`

export const LangWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 286px;
`

export const StyledArrow = styled.div`
  width: 15px;
  height: 11px;
  display: flex;
  margin-left: 30px;

  img {
    width: 100%;
    height: auto;
  }
`
