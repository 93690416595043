export const navigation = {
	items: [
		{
			target: '#el-2',
			title: {
				de: 'Einführung',
				en: 'Introduction',
				it: 'Introduzione',
				fr: 'Introduction',
			}
		},
		{
			target: '#el-3',
			title: {
				de: 'Stab- und Winkelschleifer',
				en: 'Die and angle grinders',
				it: 'X',
				fr: 'X',
				es: 'X',
				pl: 'X',
				ru: 'X'
			}
		},
		{
			target: '#el-31',
			title: {
				de: 'Tellerschleifer & Polierer',
				en: 'Disc Sander & Polisher',
				it: 'X',
				fr: 'X',
				es: 'X',
				pl: 'X',
				ru: 'X'
			}
		},
		{
			target: '#el-311',
			title: {
				de: 'Ratschenschrauber',
				en: 'Ratchet Wrenches',
				it: 'X',
				fr: 'X',
				es: 'X',
				pl: 'X',
				ru: 'X'
			}
		},
		{
			target: '#el-6',
			title: {
				de: 'Kontakt',
				en: 'Contact',
				it: 'Contatto',
				fr: 'Contact',
				es: 'X',
				pl: 'X',
				ru: 'X'
			}
		},
	],
	languages: [
		{ name: 'Deutsch', id: 'de' },
		{ name: 'English', id: 'en' },
		// { name: 'Italiano', id: 'it' },
		// { name: 'Français', id: 'fr' },
		// { name: 'Español', id: 'es' },
		// { name: 'Polish', id: 'pl' },
		// { name: 'Russian', id: 'ru' },
	],
}