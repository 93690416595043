import React, { useRef } from 'react';
import styled from "styled-components";
import ProductBox from "../ProductBox/ProductBox";
import { H2, H5 } from "../../styled/Typo";
import { useSelector } from "react-redux";
import { Container } from "../../styled/Container";
import 'swiper/css';
import 'swiper/css/grid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import theme from "../../styled/Theme";
import 'swiper/css/pagination';


const StyledContainer = styled( Container )`
  max-width: calc(1313px - 1rem);
  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: 69px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: 68px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-top: 69px
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 0 32px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    padding: 0 32px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    padding: 0 1rem
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: 38px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: 57px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-top: 64px
  }

  .swiper-pagination {
    .swiper-pagination-bullet {
      border: 1px solid white;

      &.swiper-pagination-bullet-active {
        background: white;
      }
    }
  }
`

const StyledProductBox = styled.div`
  margin-right: 15px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  * {
    text-decoration: none;
  }
`

const ProductGrid = ({ headline, overline, products }) => {
	const { lang } = useSelector( state => state.language )
	const onClick = () => {
		// console.log( 'ProductGrid' )
	}

	const bullets = useRef()

	return (
			<StyledContainer>
				<H5 uppercase primary>{overline[lang]}</H5>
				<H2 uppercase>{headline[lang]}</H2>
				<Wrapper>
					<Swiper
							modules={[ Pagination, Autoplay ]}
							autoplay={{delay: 2000}}
							breakpoints={{
								// when window width is >= 640px

								0: {
									watchOverflow: true,
									slidesPerView: 1.2,
									autoplay: { delay: 2000 }
								},
								510: {
									watchOverflow: true,
									slidesPerView: 2.2,
									autoplay: { delay: 2000 }
								},
								992: {
									slidesPerView: 3.2,
									watchOverflow: true,
									autoplay: { delay: 2000 }


								},
								1200: {
									watchOverflow: true,
									slidesPerView: 4,
									grid: { rows: 2 },
									autoplay: false
								},
							}}
					>
						{products.map( (product, key) => (
								<SwiperSlide key={key}>
									<StyledProductBox as={'a'} target="_blank" href={product.url[lang]}>
										<ProductBox {...product}/>
									</StyledProductBox>
								</SwiperSlide>
						) )}

					</Swiper>
					<div ref={bullets}/>


				</Wrapper>
			</StyledContainer>

	);
};

export default ProductGrid;