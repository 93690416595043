export const footer = {
	contact: {
		headline: {
			de: 'KONTAKTINFORMATION',
			en: 'CONTACT INFORMATION',
			it: 'CONTACT INFORMATION',
			fr: 'INFORMATION DE CONTACT\n',
			es: 'INFORMATION DE CONTACTO',
			pl: 'X',
			ru: 'X'
		},
		copy: {
			de: 'RODCRAFT Central Europe <br/>\n' +
					'Edmund-Seng-Str. 3-5 <br/>\n' +
					'63477 DE-Maintal<br/>\n' +
					'Telefon:<br/>\n' +
					'<a href="tel: +49 (0)6181 411-130">+49 (0)6181 411-130</a>',
			en: 'SALES OFFICE<br/>\n' +
					'38, rue Bobby Sands<br/>\n' +
					'ZAC de la Lorie<br/>\n' +
					'44800 Saint Herblain - France<br/>\n' +
					'Phone:<br/>\n' +
					'<a href="tel: +33 (0)2 40 80 20 00">+33 (0)2 40 80 20 00</a>',
			it: 'RODCRAFT ITALIA<br> Telefon:<br><a href="tel: +39 334.641.69.01">+39 334.641.69.01</a>',
			fr: 'SERVICE COMMERCIAL 38,<br> rue Bobby Sands<br>' +
					'ZAC de la Lorie<br>' +
					'44800 Saint Herblain - France<br> Téléphone:<br><a href="tel: +33 2 40 80 20 00">+33 2 40 80 20 00</a>',
			es: 'RODCRAFT ESPAÑA<br> Telefon:<br><a href="tel: +34 915 903 152">+34 915 903 152</a>',
			pl: 'X',
			ru: 'X'
		},
		button: {
			text: {
				de: 'Kontaktanfrage',
				en: 'Contact us',
				it: 'RICHIESTA DI CONTATTO',
				fr: 'CONTACTEZ-NOUS',
				es: 'SOLICITUD DE CONTACTO',
				pl: 'X',
				ru: 'X'
			},
			link: {
				de: '#el-6',
				en: '#el-6',
				it: '#el-6',
				fr: '#el-6',
				es: '#el-6',
				pl: '#el-6',
				ru: '#el-6'
			}
		},
	},
	newsletter: {
		headline: {
			de: 'RODCRAFT NEWSLETTER',
			en: 'STAY CONNECTED WITH US:',
			it: 'RIMANETE INFORMATI:',
			fr: 'RESTEZ CONNECTES AVEC RODCRAFT:',
			es: 'MANTÉNGANSE AL TANTO',
			pl: 'X',
			ru: 'X'
		},
		copy: {
			de: 'Abonnieren Sie unseren Newsletter mit aktuellsten Informationen über Produkte, Aktionen und Neuheiten. Melden Sie sich mit Ihrer E-Mail Adresse für unseren Newsletter an.',
			en: 'Subscribe to our newsletter: get all the latest information on products, sales and news. Subscribe to our newsletter today by entering your email address',
			it: 'Iscriviti alla nostra newsletter: ottieni tutte le ultime informazioni su prodotti, vendite e novità. Iscriviti oggi alla nostra newsletter inserendo il tuo indirizzo email',
			fr: 'Inscrivez-vous à notre newsletter en entrant votre adresse e-mail pour recevoir nos dernières informations sur nos produits et promotions',
			es: 'Suscríbase a nuestro boletín informativo: obtenga toda la información más reciente sobre productos, ventas y noticias. Suscríbase a nuestro boletín hoy ingresando su dirección de correo electrónico',
			pl: 'X',
			ru: 'X'
		},
		button: {
			text: {
				de: 'Jetzt abonnieren',
				en: 'Subscribe',
				it: 'SOTTOSCRIVERE',
				fr: 'INSCRIPTION',
				es: 'SUSCRIBIRSE',
				pl: 'X',
				ru: 'X'
			},
			link: {
				de: 'https://www.rodcraft.com/de/newsletter-abonnement',
				en: 'https://www.rodcraft.com/de/newsletter-abonnement',
				it: 'https://www.rodcraft.com/de/newsletter-abonnement',
				fr: 'https://www.rodcraft.com/de/newsletter-abonnement',
				es: 'https://www.rodcraft.com/de/newsletter-abonnement',
				pl: 'https://www.rodcraft.com/de/newsletter-abonnement',
				ru: 'https://www.rodcraft.com/de/newsletter-abonnement'
			}
		},
	},
	account: {
		headline: {
			de: 'Konto und Einstellungen',
			en: 'My Account',
			it: 'ACCOUNT E IMPOSTAZIONI',
			fr: 'MON COMPTE',
			es: 'CUENTA Y AJUSTES',
			pl: 'X',
			ru: 'X'
		},
		links: [
			{
				name: {
					de: 'Mein Account',
					en: 'About Rodcraft',
					it: 'Il mio account',
					fr: 'A propos de Rodcraft',
					es: 'Mi cuenta',
					pl: 'X',
					ru: 'X'
				},
				link: {
					de: 'https://www.rodcraft.com/de/customer/account/',
					en: 'https://www.rodcraft.com/gb/about_rodcraft/',
					it: 'https://www.rodcraft.com/it/customer/account/',
					fr: 'https://www.rodcraft.com/fr/a-propos-rodcraft/',
					es: 'https://www.rodcraft.com/es/customer/account/',
					pl: 'X',
					ru: 'X'
				}
			},
			{
				name: {
					de: 'Login',
					en: 'Customer Centers',
					it: 'Accesso',
					fr: 'Centres clients',
					es: 'Iniciar sesión',
					pl: 'X',
					ru: 'X'
				},
				link: {
					de: 'https://www.rodcraft.com/de/customer/account/login/',
					en: 'https://www.rodcraft.com/gb/global-contact/',
					it: 'https://www.rodcraft.com/it/customer/account/login/',
					fr: 'https://www.rodcraft.com/fr/global-contact/',
					es: 'https://www.rodcraft.com/es/customer/account/login/',
					pl: 'X',
					ru: 'X'
				}
			},
			{
				name: {
					de: 'Fragen und Antworten',
					en: 'Legal Notice, Privacy Policys',
					it: 'domande e risposte',
					fr: 'Mentions légales',
					es: 'preguntas y respuestas',
					pl: 'X',
					ru: 'X'
				},
				link: {
					de: 'https://www.rodcraft.com/de/faq/',
					en: 'https://www.rodcraft.com/gb/uk-legal/',
					it: 'https://www.rodcraft.com/it/faq/',
					fr: 'https://www.rodcraft.com/fr/fr-mentions-legales/',
					es: 'https://www.rodcraft.com/es/faq/',
					pl: 'X',
					ru: 'X'
				}
			},
			{
				name: {
					de: 'Lieferungen',
					en: 'Advanced Search',
					it: 'Contattaci',
					fr: 'Recherche avancée',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				link: {
					de: 'https://www.rodcraft.com/de/lieferungen/',
					en: 'https://www.rodcraft.com/gb/catalogsearch/advanced/',
					it: 'https://www.rodcraft.com/it/support-desk/',
					fr: 'https://www.rodcraft.com/fr/catalogsearch/advanced/',
					es: 'X',
					pl: 'X',
					ru: 'X'
				}
			},
			{
				name: {
					de: 'Zahlungsweisen',
					en: '',
					it: 'Catalogo',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				link: {
					de: 'https://www.rodcraft.com/de/zahlungsweisen/',
					en: '',
					it: 'https://www.rodcraft.com/it/catalogues-pdf-it/',
					es: 'X',
					pl: 'X',
					ru: 'X'
				}
			},
			{
				name: {
					de: 'Seitenverzeichnis',
					en: 'Site map',
					it: 'Mappa del sito',
					fr: 'Plan du site',
					es: 'plano del sitio',
					pl: 'X',
					ru: 'X'
				},
				link: {
					de: 'https://www.rodcraft.com/de/sitemap/',
					en: 'https://www.rodcraft.com/gb/sitemap/',
					it: 'https://www.rodcraft.com/it/sitemap/',
					fr: 'https://www.rodcraft.com/fr/sitemap/',
					es: 'https://www.rodcraft.com/es/sitemap/',
					pl: 'https://www.rodcraft.com/pl/sitemap/',
					ru: 'https://www.rodcraft.com/ru/sitemap/'
				}
			},
		]
	},
	terms: {
		headline: {
			de: 'Benutzerbedingungen',
			en: '',
			it: 'NOTE LEGALI',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		links: [
			{
				name: {
					de: 'AGBs',
					en: '',
					it: 'Condizioni generali di vendita',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				link: {
					de: 'https://www.rodcraft.com/de/customer/account/',
					en: '',
					it: 'Condizioni generali di vendita',
					es: 'X',
					pl: 'X',
					ru: 'X'
				}
			},
			{
				name: {
					de: 'Nutzungsbedingungen der Website',
					en: '',
					it: 'Condizioni di utilizzo del sito web',
				},
				link: {
					de: 'https://www.rodcraft.com/de/nutzungsbedingungen-der-website/',
					en: '',
					it: 'https://www.rodcraft.com/it/Condizioni-di-utilizzo-del-sito-web/',
					es: 'X',
					pl: 'X',
					ru: 'X'
				}
			},
			{
				name: {
					de: 'Impressum',
					en: '',
					it: 'Protezione dati',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				link: {
					de: 'https://www.rodcraft.com/de/Impressum/',
					en: '',
					it: 'https://www.rodcraft.com/it/Protezione-dati/',
					es: 'X',
					pl: 'X',
					ru: 'X'
				}
			},
			{
				name: {
					de: 'Datenschutzerklärung',
					en: '',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				link: {
					de: 'https://www.rodcraft.com/de/datenschutzerklarung/',
					en: '',
					es: 'X',
					pl: 'X',
					ru: 'X'
				}
			},
		]
	},
	social: {
		headline: {
			de: 'Social Media',
			en: 'Social Media',
			it: 'Social Media',
			fr: 'RESEAUX SOCIAUX',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		links: {
			linkedIn: 'https://www.linkedin.com/company/rodcraft/',
			facebook: 'https://www.facebook.com/Rodcraft.de/',
			instagram: 'https://instagram.com/rodcraft_central_europe?igshid=101l84ju0q1fc',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		headline2: {
			de: 'Sichere Zahlungen',
			en: 'Secure Payment',
			it: 'PAGAMENTO SICURO AL 100%',
			fr: 'Paiements sécurisés',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		link: {
			de: 'https://www.rodcraft.com/de/zahlungsweisen/',
			en: '',
			it: 'https://www.rodcraft.com/it/pagamenti/',
			es: 'X',
			pl: 'X',
			ru: 'X'
		}
	}
}