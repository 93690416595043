import React from 'react';
import Logo from '../../images/logo_rodcraft.svg'
import { Header, LangWrapper, NavItem, NavLink, NavList } from "./Styles";
import { LangDropDown } from "./LanguageDropdown";
import { useSelector } from "react-redux";


const Navigation = ({ logo, items,languages }) => {
	const { lang } = useSelector(state => state.language)
	return (
			<Header>
				<img src={Logo} alt="Rodcraft"/>
				<NavList>
					{items.map( (item, key) => (
							<NavItem key={key} as="li">
								<NavLink as="a" href={item.target}>{item.title[lang]}</NavLink>
							</NavItem>
					) )}
				</NavList>
				<LangWrapper>
					<LangDropDown languages={languages}/>
				</LangWrapper>

			</Header>
	);
};

export default Navigation;