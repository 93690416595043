import React from 'react';
import Header from "../Header/Header";
import ImageSection from "../ImageSection/ImageSection";
import IntroText from "../IntroText/IntroText";
import ProductList from "../Product/ProductList/ProductList";
import ProductGrid from "../Product/ProductGrid/ProductGrid";
import ContactForm from "../ContactForm/ContactForm";
import Register from "../Product/Register/Register";
import Video from '../Video/Video';

const ContentSwitcher = ({content}) => {

	const Element = ({el}) => {
		switch ( el.type ) {
			case 'Header':
				return <Header {...el}/>

			case 'IntroText':
				return <IntroText {...el}/>

			case 'ImageSection':
				return <ImageSection  {...el}/>

			case 'ProductGrid':

				return <ProductGrid {...el}/>

			case 'ProductList':
				return <ProductList {...el}/>

			case 'Register':
				return <Register {...el}/>

			case 'ContactForm':
				return <ContactForm {...el} />

			case 'Video':
				return <Video {...el} />

			default:
				return <p>Element is missing</p>
		}
	}

	return (
			<>
				{content.map((el, key) => (
						<div id={`el-${el.id}`} key={key}>
							<Element el={el}/>
						</div>
				))}
			</>
	);
};

export default ContentSwitcher;