// Stab & Winkelschlefier
import RC7001 from '../images/RC7001small.png'
import RC7102 from '../images/RC7102small.png'
import RC7103 from '../images/RC7103_top.png'

import RC7001Gallery1 from '../images/RC7001-gallery1.jpg'
import RC7001Gallery2 from '../images/RC7001-gallery2.jpg'
import RC7001Gallery3 from '../images/RC7001-gallery3.jpg'

import RC7102Gallery1 from '../images/RC7102-gallery1.jpg'
import RC7102Gallery2 from '../images/RC7102-gallery2.jpg'
import RC7102Gallery3 from '../images/RC7102-gallery3.jpg'

import RC7103Gallery1 from '../images/RC7103-gallery1.jpg'
import RC7103Gallery2 from '../images/RC7103-gallery2.jpg'
import RC7103Gallery3 from '../images/RC7103-gallery3.jpg'



import RC7001Big from "../images/RC7001_left_big.png";
import RC7102Big from "../images/RC7102_left.png";
import RC7103Big from "../images/RC7103_top.png";


// Tellerschleifer & Polierer
import RC7601 from '../images/RC7601_3D-left.png'
import RC7602 from "../images/RC7602_3D-left.png";

import RC7601Gallery1 from '../images/RC7601-gallery1.jpg'
import RC7601Gallery2 from '../images/RC7601-gallery2.jpg'
import RC7601Gallery3 from '../images/RC7601-gallery3.jpg'

import RC7602Gallery1 from '../images/RC7602-gallery1.jpg'
import RC7602Gallery2 from '../images/RC7602-gallery2.jpg'
import RC7602Gallery3 from '../images/RC7602-gallery3.jpg'

import RC7601Big from "../images/RC7601_big.png";
import RC7602Big from "../images/RC7602_big.png";


// Ratschenschrauber
import RC3201 from '../images/RC3201_3D-left.png'
import RC3202 from "../images/RC3202_top.png";
import RC3203 from "../images/RC3203_left.png";

import RC3201Gallery1 from '../images/RC3201-gallery1.jpg'
import RC3201Gallery2 from '../images/RC3201-gallery2.jpg'
import RC3201Gallery3 from '../images/RC3201-gallery3.jpg'

import RC3202Gallery1 from '../images/RC3202-gallery1.jpg'
import RC3202Gallery2 from '../images/RC3202-gallery2.jpg'
import RC3202Gallery3 from '../images/RC3202-gallery3.jpg'

import RC3203Gallery1 from '../images/RC3203-gallery1.jpg'
import RC3203Gallery2 from '../images/RC3203-gallery2.jpg'
import RC3203Gallery3 from '../images/RC3203-gallery3.jpg'


import RC3201Big from "../images/RC3201_big.png";
import RC3202Big from "../images/RC3202_big.png";
import RC3203Big from "../images/RC3203_big.png";



// Backgrounds
import Header from "../images/Header.jpg";
import Bgone from "../images/BGone.jpg";
import Bgtwo from "../images/bg-tellerschleifer.jpg";
import Bgthree from "../images/bg-ratschenschrauber.jpg";


// PDF's
import PDFde from '../images/22_0056_RC_6P_Miniserie_DE_Seiten.pdf'
import PDFen from '../images/22_0056_RC_6P_Miniserie_DE_V03_german.pdf'
import PDFit from '../images/22_0056_RC_6P_Miniserie_DE_V03_german.pdf'
import PDFpl from '../images/22_0056_RC_6P_Miniserie_DE_V03_german.pdf'
import PDFfr from '../images/22_0056_RC_6P_Miniserie_DE_V03_german.pdf'


export const content = [
	{
		id: 1,
		type: 'Header',
		overline: {
			de: 'Für das Arbeiten unter beengten Platzverhältnissen',
			en: 'For working in confined spaces',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		headline: {
			de: 'Die neue Rodcraft Mini-Serie',
			en: 'The new Rodcraft Mini-Series',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		image: Header
	},
	{
		id: 2,
		type: 'IntroText',
		overline: {
			de: 'Mini-Serie',
			en: 'Mini-Series',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		headline: {
			de: 'Klein, kraftvoll und für alle Fälle',
			en: 'Short, powerful and for all needs',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		copy: {
			de: '<p>Die neue Produktfamilie umfasst acht Werkzeuge, welche die klassischen Arbeiten unter beengten Platzverhältnissen im Fahrzeugservice und der industriellen Instandhaltung abdecken. Mit sehr kleiner Bauform, niedrigem Gewicht und hoher Leistung sind die ergonomischen Werkzeuge in gewohnt hoher Rodcraft Qualität auch für den professionellen und dauerhaften Einsatz entwickelt.<br><br>Alle acht Werkzeuge werden mit einem kraftvollen 223 Watt Druckluftmotor betrieben und erlauben eine feine Drehzahlregulierung am Auslöser. Das Komposit-Gehäuse sorgt neben dem geringen Gewicht für optimale Kälteisolierung sowie für hohen Arbeitskomfort und guten Grip. Die Serie beinhaltet drei Schleifer. Darunter zwei Stabschleifer, einen in gerader und einen in 90° abgewinkelter Bauform, für Schleifwerkzeuge mit 6 mm Durchmesser sowie einen Mini-Winkelschleifer für Schleifscheiben mit 50 bzw. 59 mm Durchmesser.<br><br> Weiterhin umfasst die Mini Series einen Tellerschleifer mit 18.000 U/min und eine Poliermaschine, die mit der reduzierten Drehzahl von 4.500 U/min optimal für Polieraufgaben ausgelegt ist. Die drei Druckluftratschen komplettieren die Serie und decken mit ihrer Funktionsvielfalt eine sehr große Bandbreitean Anwendungen ab. So hat man die Wahl zwischen einem Werkzeug mit 3/8 Zoll Abtrieb und einem Multifunktionswerkzeug mit fünf wechselbaren Werkzeugadaptern, die dritte Ratsche mit 3/8 Zoll Abtrieb ist zusätzlich mit einem Stift-Schlagwerk für sicheres, reaktionsfreies Arbeiten ausgestattet.</p>',
			en: "<p>The new product family includes eight tools that cover the classic applications in confined spaces in vehicle service and industrial maintenance. With a very short design, low weight and high performance, the ergonomic tools are also developed in the usual high Rodcraft quality for professional and durable use. <br><br> All eight tools are powered by a powerful 223 watt air motor and allow fine speed control on the trigger. In addition to the low weight, the composite housing ensures optimum cold insulation as well as high working comfort and good grip. The series includes three grinders. These include two die grinders, one in a straight and one in a 90 angled design, for grinding tools with a diameter of 6 mm, as well as a mini angle grinder for grinding wheels with diameters of 50 and 59 mm respectively.   <br><br>Furthermore, the Mini-Series includes a disc sander with 18,000 rpm and a polisher, which is optimally designed for polishing tasks with the reduced speed of 4,500 rpm. The three pneumatic ratchets complete the series and cover a very wide range of applications with their variety of functions. Thus, there is a choice between a tool with 3/8'' anvil and a multifunction tool with five interchangeable tool adapters; the third ratchet with 3/8'' anvil is additionally equipped with a pin clutch impact mechanism for safe, reaction-free work. </p>",
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		buttonText: {
			de: 'Weiterlesen',
			en: 'Read more',
			it: 'Continua',
			fr: 'Continuer',
			es: 'X',
			pl: 'X',
			ru: 'X'
		}
	},
	{
		id: 3,
		type: 'ImageSection',
		overline: {
			de: 'Mini-Serie',
			en: 'Mini-Series',
			fr: 'Mini-Serie',
			it: 'Mini-Serie',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		headline: {
			de: 'Stab- und Winkelschleifer',
			en: 'Die and angle grinders',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		image: Bgone
	},
	{
		id: 4, type: 'ProductList', products: [
			{
				id: 'RC7001',
				name: {
					de: 'RC7001',
					en: 'RC7001',
					it: 'RC7001',
					fr: 'RC7001',
					es: 'RC7001',
					pl: 'RC7001',
					ru: 'RC7001'
				},
				excerpt: {
					de: 'Nur 116 mm lang, bis zu 25.000 U/min, 6 mm Spannzange',
					en: 'Only 116 mm long, up to 25.000 rpm, with 6 mm collet ',
					it: 'X',
					fr: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC7001,
				url: {
					de: 'https://www.rodcraft.com/de/8951000428.html',
					en: 'https://www.rodcraft.com/gb/8951000428.html',
					it: 'https://www.rodcraft.com/it/8951000428.html',
					fr: 'https://www.rodcraft.com/fr/8951000428.html',
					es: 'https://www.rodcraft.com/es/8951000428.html',
					pl: 'https://www.rodcraft.com/pl/8951000428.html',
					ru: 'https://www.rodcraft.com/ru/8951000428.html'
				}
			},
			{
				id: 'RC7102',
				name: {
					de: 'RC7102',
					en: 'RC7102',
					it: 'RC7102',
					fr: 'RC7102',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				excerpt: {
					de: 'Nur 127 mm, bis zu 18.000 U/min, 6 mm Spannzange',
					en: 'Only 127 mm long, up to 18,000 rpm, with 6 mm collet',
					it: 'X',
					fr: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC7102,
				url: {
					de: 'https://www.rodcraft.com/de/8951000429.html',
					en: 'https://www.rodcraft.com/gb/8951000429.html',
					it: 'https://www.rodcraft.com/it/8951000429.html',
					fr: 'https://www.rodcraft.com/fr/8951000429.html',
					es: 'https://www.rodcraft.com/es/8951000429.html',
					pl: 'https://www.rodcraft.com/pl/8951000429.html',
					ru: 'https://www.rodcraft.com/ru/8951000429.html'
				}
			},
			{
				id: 'RC7103',
				name: {
					de: 'RC7103',
					en: 'RC7103',
					it: 'RC7103',
					fr: 'RC7103',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				excerpt: {
					de: 'Nur 129 mm lang, bis zu 18.000 U/min, für 50 - 59 mm Trennscheiben (2“-2,5“)',
					en: 'Only 129 mm long, up to 18,000 rpm, for 50 to 59 mm cut-off wheels (2"-2.5")',
					it: 'X',
					fr: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC7103,
				url: {
					de: 'https://www.rodcraft.com/de/8951000430.html',
					en: 'https://www.rodcraft.com/gb/8951000430.html',
					it: 'https://www.rodcraft.com/it/8951000430.html',
					fr: 'https://www.rodcraft.com/fr/8951000430.html',
					es: 'https://www.rodcraft.com/es/8951000430.html',
					pl: 'https://www.rodcraft.com/pl/8951000430.html',
					ru: 'https://www.rodcraft.com/ru/8951000430.html'
				}
			},
		]
	},
	{
		id: 'R-RC7001',
		type: 'Register',
		productId: 'RC7001',
		overline: {
			de: 'RC7001',
			en: 'RC7001',
			it: 'RC7001',
			fr: 'RC7001',
			es: 'RC7001',
			pl: 'RC7001',
			ru: 'RC7001'
		},
		headline: {
			de: 'Mini Stabschleifer',
			en: 'Mini die grinder',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000428.html',
			en: 'https://www.rodcraft.com/gb/8951000428.html',
			it: 'https://www.rodcraft.com/it/8951000428.html',
			fr: 'https://www.rodcraft.com/fr/8951000428.html',
			es: 'https://www.rodcraft.com/es/8951000428.html',
			pl: 'https://www.rodcraft.com/pl/8951000428.html',
			ru: 'https://www.rodcraft.com/ru/8951000428.html'
		},
		pdf: {
			de: PDFde,
			// en: PDFen,
			it: PDFit,
			fr: PDFfr,
			es: 'X',
			pl: PDFpl,
			ru: 'X'
		},
		image: RC7001Big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Leistungsstark',
							en: 'Powerful',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '223-Watt-Druckluftmotor',
							en: '223 watt air motor',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '50%',
						y: '-2%'
					},
					{
						headline: {
							de: 'Langlebig',
							en: 'Durable',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Robuste Mechanik in Metall für lange Lebensdauer',
							en: 'Robust mechanics in metal for long service life',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '21%',
						y: '36%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité',
				},
				points: [
					{
						headline: {
							de: 'Hohe Sicherheit',
							en: 'High safety',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Inkl. Sicherheitsauslöser (Ratschen mit normalem Auslöser)',
							en: 'Incl. safety trigger (ratchets with normal trigger)',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '63%',
						y: '67%'
					}
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: ' ',
							en: ' ',
							it: ' ',
							fr: ' ',
							es: ' ',
							pl: ' ',
							ru: ' '
						},
						copy: {
							de: 'Für den geraden Zugang zum Werkstück',
							en: 'For straight access to the workpiece',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '4%',
						y: '14%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High comfort',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Kälteisolierendes und leichtes	Komposit-Gehäuse',
							en: 'Cold-insulating and lightweight composite housing',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '53%',
						y: '-7%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '<ul><li>– Schlankes, ergonomisch geformtes Gehäuse, welches perfekt in der Hand liegt</li><li>– Kurze Bauform, perfekt für	beengte Arbeitsbereiche</li></ul>',
							en: '<ul><li>– Slim, ergonomically designed housing that fits perfectly in the hand</li><li>– Short design, perfect for confined work areas</li></ul>',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '81%',
						y: '-6%'
					}
				]
			},
		],
		gallery: {
			imageOne: RC7001Gallery1,
			imageTwo: RC7001Gallery2,
			imageThree: RC7001Gallery3,
		}
	},
	{
		id: 'R-RC7102',
		type: 'Register',
		productId: 'RC7102',
		overline: {
			de: 'RC7102',
			en: 'RC7102',
			it: 'RC7102',
			fr: 'RC7102',
			es: 'RC7102',
			pl: 'RC7102',
			ru: 'RC7102'
		},
		headline: {
			de: 'Mini-Stabschleifer',
			en: 'Mini die grinder',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000429.html',
			en: 'https://www.rodcraft.com/gb/8951000429.html',
			it: 'https://www.rodcraft.com/it/8951000429.html',
			fr: 'https://www.rodcraft.com/fr/8951000429.html',
			es: 'https://www.rodcraft.com/es/8951000429.html',
			pl: 'https://www.rodcraft.com/pl/8951000429.html',
			ru: 'https://www.rodcraft.com/ru/8951000429.html'
		},
		pdf: {
			de: PDFde,
			// en: PDFen,
			it: PDFit,
			fr: PDFfr,
			es: 'X',
			pl: PDFpl,
			ru: 'X'
		},
		image: RC7102Big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Leistungsstark',
							en: 'Powerful',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '223-Watt-Druckluftmotor',
							en: '223 watt air motor',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '50%',
						y: '-2%'
					},
					{
						headline: {
							de: 'Langlebig',
							en: 'Durable',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Robuste Mechanik in Metall für lange Lebensdauer',
							en: 'Robust mechanics in metal for long service life',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '21%',
						y: '50%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Hohe Sicherheit',
							en: 'High safety',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Inkl. Sicherheitsauslöser (Ratschen mit normalem Auslöser)',
							en: 'Incl. safety trigger (ratchets with normal trigger)',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '63%',
						y: '62%'
					}
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: ' ',
							en: ' ',
							it: ' ',
							fr: ' ',
							es: ' ',
							pl: ' ',
							ru: ' '
						},
						copy: {
							de: 'Schleifkopf 90° abgewinkelt für schwer zugängliche Stellen',
							en: 'Grinding head angled 90° for hard to reach places',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '4%',
						y: '14%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High comfort',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Kälteisolierendes und leichtes	Komposit-Gehäuse',
							en: 'Cold-insulating and lightweight composite housing',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '53%',
						y: '-7%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '<ul><li>– Schlankes, ergonomisch geformtes Gehäuse, welches perfekt in der Hand liegt</li><li>– Kurze Bauform, perfekt für	beengte Arbeitsbereiche</li></ul>',
							en: '<ul><li>– Slim, ergonomically designed housing that fits perfectly in the hand</li><li>– Short design, perfect for confined work areas</li></ul>',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '81%',
						y: '-6%'
					}
				]
			},
		],
		gallery: {
			imageOne: RC7102Gallery1,
			imageTwo: RC7102Gallery2,
			imageThree: RC7102Gallery3,
		}
	},
	{
		id: 'R-RC7103',
		type: 'Register',
		productId: 'RC7103',
		overline: {
			de: 'RC7103',
			en: 'RC7103',
			it: 'RC7103',
			fr: 'RC7103',
			es: 'RC7103',
			pl: 'RC7103',
			ru: 'RC7103'
		},
		headline: {
			de: 'Mini-Winkelschleifer',
			en: 'Mini angle grinder',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000430.html',
			en: 'https://www.rodcraft.com/gb/8951000430.html',
			it: 'https://www.rodcraft.com/it/8951000430.html',
			fr: 'https://www.rodcraft.com/fr/8951000430.html',
			es: 'https://www.rodcraft.com/es/8951000430.html',
			pl: 'https://www.rodcraft.com/pl/8951000430.html',
			ru: 'https://www.rodcraft.com/ru/8951000430.html'
		},
		pdf: {
			de: PDFde,
			// en: PDFen,
			it: PDFit,
			fr: PDFfr,
			es: 'X',
			pl: PDFpl,
			ru: 'X'
		},
		image: RC7103Big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Leistungsstark',
							en: 'Powerful',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '223-Watt-Druckluftmotor',
							en: '223 watt air motor',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '50%',
						y: '10%'
					},
					{
						headline: {
							de: 'Langlebig',
							en: 'Durable',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Robuste Mechanik in Metall für lange Lebensdauer',
							en: 'Robust mechanics in metal for long service life',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '21%',
						y: '36%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Hohe Sicherheit',
							en: 'High safety',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Inkl. Sicherheitsauslöser (Ratschen mit normalem Auslöser)',
							en: 'Incl. safety trigger (ratchets with normal trigger)',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '63%',
						y: '71%'
					}
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: ' ',
							en: ' ',
							it: ' ',
							fr: ' ',
							es: ' ',
							pl: ' ',
							ru: ' '
						},
						copy: {
							de: 'Kompakte Bauform für das Trennen und Schleifen an beengten Stellen',
							en: 'Compact design for cutting and grinding in confined spaces',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '4%',
						y: '14%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High comfort',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Kälteisolierendes und leichtes	Komposit-Gehäuse',
							en: 'Cold-insulating and lightweight composite housing',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '53%',
						y: '11%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '<ul><li>– Schlankes, ergonomisch geformtes Gehäuse, welches perfekt in der Hand liegt</li><li>– Kurze Bauform, perfekt für	beengte Arbeitsbereiche</li></ul>',
							en: '<ul><li>– Slim, ergonomically designed housing that fits perfectly in the hand</li><li>– Short design, perfect for confined work areas</li></ul>',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '81%',
						y: '12%'
					}
				]
			},
		],
		gallery: {
			imageOne: RC7103Gallery1,
			imageTwo: RC7103Gallery2,
			imageThree: RC7103Gallery3,
		}
	},
	{
		id: 31,
		type: 'ImageSection',
		overline: {
			de: 'Mini-Serie',
			en: 'Mini-Series',
			it: 'Mini-Serie',
			fr: 'Mini-Serie',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		headline: {
			de: 'Tellerschleifer & Polierer',
			en: 'Disc Sander & Polisher',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		image: Bgtwo
	},
	{
		id: 41, type: 'ProductList', products: [
			{
				id: 'RC7601',
				name: {
					de: 'RC7601',
					en: 'RC7601',
					fr: 'RC7601',
					it: 'RC7601',
					es: 'RC7601',
					pl: 'RC7601',
					ru: 'RC7601'
				},
				excerpt: {
					de: 'Nur 126 mm, bis zu 18.000 U/min, für 50 mm Schnellwechsel-Schleifpads',
					en: 'Only 126 mm long, up to 18,000 rpm, for 50 mm quick-change polishing pads',
					it: 'X',
					fr: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC7601,
				url: {
					de: 'https://www.rodcraft.com/de/8951000426.html',
					en: 'https://www.rodcraft.com/gb/8951000426.html',
					fr: 'https://www.rodcraft.com/fr/8951000426.html',
					it: 'https://www.rodcraft.com/it/8951000426.html',
					es: 'https://www.rodcraft.com/es/8951000426.html',
					pl: 'https://www.rodcraft.com/pl/8951000426.html',
					ru: 'https://www.rodcraft.com/ru/8951000426.html'
				}
			},
			{
				id: 'RC7602',
				name: {
					de: 'RC7602',
					en: 'RC7602',
					fr: 'RC7602',
					it: 'RC7602',
					es: 'RC7602',
					pl: 'RC7602',
					ru: 'RC7602'
				},
				excerpt: {
					de: 'Nur 152 mm lang, bis zu 4.500 U/min, für 50 mm Schnellwechsel-Schleifpads',
					en: 'Only 152 mm long, up to 4,500 rpm, for 50 mm quick-change polishing pads',
					it: 'X',
					fr: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC7602,
				url: {
					de: 'https://www.rodcraft.com/de/8951000427.html',
					en: 'https://www.rodcraft.com/gb/8951000427.html',
					fr: 'https://www.rodcraft.com/fr/8951000427.html',
					it: 'https://www.rodcraft.com/it/8951000427.html',
					es: 'https://www.rodcraft.com/es/8951000427.html',
					pl: 'https://www.rodcraft.com/pl/8951000427.html',
					ru: 'https://www.rodcraft.com/ru/8951000427.html'
				}
			},
		]
	},
	{
		id: 'R-RC7601',
		type: 'Register',
		productId: 'RC7601',
		overline: {
			de: 'RC7601',
			en: 'RC7601',
			it: 'RC7601',
			fr: 'RC7601',
			es: 'RC7601',
			pl: 'RC7601',
			ru: 'RC7601'
		},
		headline: {
			de: 'Mini Tellerschleifer',
			en: 'Mini disc sander',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000431.html',
			en: 'https://www.rodcraft.com/gb/8951000431.html',
			it: 'https://www.rodcraft.com/it/8951000431.html',
			fr: 'https://www.rodcraft.com/fr/8951000431.html',
			es: 'https://www.rodcraft.com/es/8951000431.html',
			pl: 'https://www.rodcraft.com/pl/8951000431.html',
			ru: 'https://www.rodcraft.com/ru/8951000431.html'
		},
		pdf: {
			de: PDFde,
			// en: PDFen,
			it: PDFit,
			fr: PDFfr,
			es: 'X',
			pl: PDFpl,
			ru: 'X'
		},
		image: RC7601Big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Leistungsstark',
							en: 'Powerful',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '223-Watt-Druckluftmotor',
							en: '223 watt air motor',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '50%',
						y: '-5%'
					},
					{
						headline: {
							de: 'Langlebig',
							en: 'Durable',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Robuste Mechanik in Metall für lange Lebensdauer',
							en: 'Robust mechanics in metal for long service life',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '21%',
						y: '36%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Hohe Sicherheit',
							en: 'High safety',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Inkl. Sicherheitsauslöser (Ratschen mit normalem Auslöser)',
							en: 'Incl. safety trigger (ratchets with normal trigger)',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '65%',
						y: '63%'
					}
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: ' ',
							en: ' ',
							it: ' ',
							fr: ' ',
							es: ' ',
							pl: ' ',
							ru: ' '
						},
						copy: {
							de: 'Kompakte Bauform für das Schleifen an beengten Stellen',
							en: 'Compact design for grinding in confined spaces',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '4%',
						y: '14%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High comfort',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Kälteisolierendes und leichtes	Komposit-Gehäuse',
							en: 'Cold-insulating and lightweight composite housing',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '53%',
						y: '-5%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '<ul><li>– Schlankes, ergonomisch geformtes Gehäuse, welches perfekt in der Hand liegt</li><li>– Kurze Bauform, perfekt für	beengte Arbeitsbereiche</li></ul>',
							en: '<ul><li>– Slim, ergonomically designed housing that fits perfectly in the hand</li><li>– Short design, perfect for confined work areas</li></ul>',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '81%',
						y: '-4%'
					}
				]
			},
		],
		gallery: {
			imageOne: RC7601Gallery1,
			imageTwo: RC7601Gallery2,
			imageThree: RC7601Gallery3,
		}
	},
	{
		id: 'R-RC7602',
		type: 'Register',
		productId: 'RC7602',
		overline: {
			de: 'RC7602',
			en: 'RC7602',
			it: 'RC7602',
			fr: 'RC7602',
			es: 'RC7602',
			pl: 'RC7602',
			ru: 'RC7602'
		},
		headline: {
			de: 'Mini Polierer',
			en: 'Mini polisher',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000432.html',
			en: 'https://www.rodcraft.com/gb/8951000432.html',
			it: 'https://www.rodcraft.com/it/8951000432.html',
			fr: 'https://www.rodcraft.com/fr/8951000432.html',
			es: 'https://www.rodcraft.com/es/8951000432.html',
			pl: 'https://www.rodcraft.com/pl/8951000432.html',
			ru: 'https://www.rodcraft.com/ru/8951000432.html'
		},
		pdf: {
			de: PDFde,
			// en: PDFen,
			it: PDFit,
			fr: PDFfr,
			es: 'X',
			pl: PDFpl,
			ru: 'X'
		},
		image: RC7602Big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Leistungsstark',
							en: 'Powerful',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '223-Watt-Druckluftmotor',
							en: '223 watt air motor',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '50%',
						y: '-7%'
					},
					{
						headline: {
							de: 'Langlebig',
							en: 'Durable',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Robuste Mechanik in Metall für lange Lebensdauer',
							en: 'Robust mechanics in metal for long service life',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '21%',
						y: '36%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Hohe Sicherheit',
							en: 'High safety',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Inkl. Sicherheitsauslöser (Ratschen mit normalem Auslöser)',
							en: 'Incl. safety trigger (ratchets with normal trigger)',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '71%',
						y: '62%'
					}
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: ' ',
							en: ' ',
							it: ' ',
							fr: ' ',
							es: ' ',
							pl: ' ',
							ru: ' '
						},
						copy: {
							de: 'Kompakte Bauform für den Einsatz an beengten Stellen',
							en: 'Compact design for use in confined spaces',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '4%',
						y: '14%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High comfort',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Kälteisolierendes und leichtes	Komposit-Gehäuse',
							en: 'Cold-insulating and lightweight composite housing',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '53%',
						y: '-7%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '<ul><li>– Schlankes, ergonomisch geformtes Gehäuse, welches perfekt in der Hand liegt</li><li>– Kurze Bauform, perfekt für	beengte Arbeitsbereiche</li></ul>',
							en: '<ul><li>– Slim, ergonomically designed housing that fits perfectly in the hand</li><li>– Short design, perfect for confined work areas</li></ul>',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '81%',
						y: '-6%'
					}
				]
			},
		],
		gallery: {
			imageOne: RC7602Gallery1,
			imageTwo: RC7602Gallery2,
			imageThree: RC7602Gallery3,
		}
	},	
	{
		id: 311,
		type: 'ImageSection',
		overline: {
			de: 'Mini-Serie',
			en: 'Mini-Series',
			it: 'Mini-Serie',
			fr: 'Mini-Serie',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		headline: {
			de: 'Ratschenschrauber',
			en: 'Ratchet Wrenches',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		image: Bgthree
	},
	{
		id: 411, type: 'ProductList', products: [
			{
				id: 'RC3201',
				name: {
					de: 'RC3201',
					en: 'RC3201',
					fr: 'RC3201',
					it: 'RC3201',
					es: 'RC3201',
					pl: 'RC3201',
					ru: 'RC3201'
				},
				excerpt: {
					de: 'Nur 135 mm lang, bis zu 300 U/min, 50 Nm Drehmoment',
					en: 'Only 135 mm long, up to 300 rpm, 50 Nm torque',
					fr: 'X',
					it: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC3201,
				url: {
					de: 'https://www.rodcraft.com/de/8951000433.html',
					en: 'https://www.rodcraft.com/gb/8951000433.html',
					fr: 'https://www.rodcraft.com/fr/8951000433.html',
					it: 'https://www.rodcraft.com/it/8951000433.html',
					es: 'https://www.rodcraft.com/es/8951000433.html',
					pl: 'https://www.rodcraft.com/pl/8951000433.html',
					ru: 'https://www.rodcraft.com/ru/8951000433.html'
				}
			},
			{
				id: 'RC3202',
				name: {
					de: 'RC3202',
					en: 'RC3202',
					fr: 'RC3202',
					it: 'RC3202',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				excerpt: {
					de: 'Nur 126 mm, bis zu 18.000 U/min, für 50 mm Schnellwechel-Schleifpads',
					en: 'Only 126 mm long, up to 18.000 rpm, for 50 mm quick-change grinding pads',
					fr: 'X',
					it: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC3202,
				url: {
					de: 'https://www.rodcraft.com/de/8951000434.html',
					en: 'https://www.rodcraft.com/gb/8951000434.html',
					fr: 'https://www.rodcraft.com/fr/8951000434.html',
					it: 'https://www.rodcraft.com/it/8951000434.html',
					es: 'https://www.rodcraft.com/es/8951000434.html',
					pl: 'https://www.rodcraft.com/pl/8951000434.html',
					ru: 'https://www.rodcraft.com/ru/8951000434.html'
				}
			},
			{
				id: 'RC3203',
				name: {
					de: 'RC3203',
					en: 'RC3203',
					fr: 'RC3203',
					it: 'RC3203',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				excerpt: {
					de: 'Nur 158 mm lang, bis zu 600 U/min,	50 Nm Drehmoment',
					en: 'Only 158 mm long, up to 600 rpm, 50 Nm torque',
					fr: 'X',
					it: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC3203,
				url: {
					de: 'https://www.rodcraft.com/de/8951000435.html',
					en: 'https://www.rodcraft.com/gb/8951000435.html',
					fr: 'https://www.rodcraft.com/fr/8951000435.html',
					it: 'https://www.rodcraft.com/it/8951000435.html',
					es: 'https://www.rodcraft.com/es/8951000435.html',
					pl: 'https://www.rodcraft.com/pl/8951000435.html',
					ru: 'https://www.rodcraft.com/ru/8951000435.html'
				}
			},
		]
	},
	{
		id: 'R-RC3201',
		type: 'Register',
		productId: 'RC3201',
		overline: {
			de: 'RC3201',
			en: 'RC3201',
			it: 'RC3201',
			fr: 'RC3201',
			es: 'RC3201',
			pl: 'RC3201',
			ru: 'RC3201'
		},
		headline: {
			de: 'Mini-3/8“ Ratschen-schrauber',
			en: 'Mini 3/8" ratchet wrench',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000433.html',
			en: 'https://www.rodcraft.com/gb/8951000433.html',
			it: 'https://www.rodcraft.com/it/8951000433.html',
			fr: 'https://www.rodcraft.com/fr/8951000433.html',
			es: 'https://www.rodcraft.com/es/8951000433.html',
			pl: 'https://www.rodcraft.com/pl/8951000433.html',
			ru: 'https://www.rodcraft.com/ru/8951000433.html'
		},
		pdf: {
			de: PDFde,
			// en: PDFen,
			it: PDFit,
			fr: PDFfr,
			es: 'X',
			pl: PDFpl,
			ru: 'X'
		},
		image: RC3201Big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Leistungsstark',
							en: 'Powerful',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '223-Watt-Druckluftmotor',
							en: '223 watt air motor',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '50%',
						y: '-8%'
					},
					{
						headline: {
							de: 'Langlebig',
							en: 'Durable',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Robuste Mechanik in Metall für lange Lebensdauer',
							en: 'Robust mechanics in metal for long service life',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '21%',
						y: '36%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Hohe Sicherheit',
							en: 'High safety',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Inkl. Sicherheitsauslöser (Ratschen mit normalem Auslöser)',
							en: 'Incl. safety trigger (ratchets with normal trigger)',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '63%',
						y: '80%'
					}
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: ' ',
							en: ' ',
							it: ' ',
							fr: ' ',
							es: ' ',
							pl: ' ',
							ru: ' '
						},
						copy: {
							de: 'Für den Einsatz an beengten Stellen',
							en: 'For use in confined spaces',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '4%',
						y: '14%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High comfort',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Kälteisolierendes und leichtes	Komposit-Gehäuse',
							en: 'Cold-insulating and lightweight composite housing',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '53%',
						y: '-7%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '<ul><li>– Schlankes, ergonomisch geformtes Gehäuse, welches perfekt in der Hand liegt</li><li>– Kurze Bauform, perfekt für	beengte Arbeitsbereiche</li></ul>',
							en: '<ul><li>– Slim, ergonomically designed housing that fits perfectly in the hand</li><li>– Short design, perfect for confined work areas</li></ul>',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '81%',
						y: '-6%'
					}
				]
			},
		],
		gallery: {
			imageOne: RC3201Gallery1,
			imageTwo: RC3201Gallery2,
			imageThree: RC3201Gallery3,
		}
	},
	{
		id: 'R-RC3202',
		type: 'Register',
		productId: 'RC3202',
		overline: { 
			de: 'RC3202',
			en: 'RC3202',
			it: 'RC3202',
			fr: 'RC3202',
			es: 'RC3202',
			pl: 'RC3202',
			ru: 'RC3202'
		},
		headline: { 
			de: 'Mini-Multifunktions-Ratschenschrauber',
			en: 'Mini multifunction ratchet wrench',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000434.html',
			en: 'https://www.rodcraft.com/gb/8951000434.html',
			it: 'https://www.rodcraft.com/it/8951000434.html',
			fr: 'https://www.rodcraft.com/fr/8951000434.html',
			es: 'https://www.rodcraft.com/es/8951000434.html',
			pl: 'https://www.rodcraft.com/pl/8951000434.html',
			ru: 'https://www.rodcraft.com/ru/8951000434.html'
		},
		pdf: {
			de: PDFde,
			// en: PDFen,
			it: PDFit,
			fr: PDFfr,
			es: 'X',
			pl: PDFpl,
			ru: 'X'
		},
		image: RC3202Big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Leistungsstark',
							en: 'Powerful',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '223-Watt-Druckluftmotor',
							en: '223 watt air motor',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '50%',
						y: '-8%'
					},
					{
						headline: {
							de: 'Langlebig',
							en: 'Durable',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Robuste Mechanik in Metall für lange Lebensdauer',
							en: 'Robust mechanics in metal for long service life',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '30%',
						y: '26%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Hohe Sicherheit',
							en: 'High safety',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Inkl. Sicherheitsauslöser (Ratschen mit normalem Auslöser)',
							en: 'Incl. safety trigger (ratchets with normal trigger)',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '73%',
						y: '45%'
					}
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: ' ',
							en: ' ',
							it: ' ',
							fr: ' ',
							es: ' ',
							pl: ' ',
							ru: ' '
						},
						copy: {
							de: 'Mit 5 Wechseleinsätzen für maximale Flexibilität',
							en: 'With 5 interchangeable inserts for maximum flexibility',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '4%',
						y: '14%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High comfort',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Kälteisolierendes und leichtes	Komposit-Gehäuse',
							en: 'Cold-insulating and lightweight composite housing',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '53%',
						y: '-7%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '<ul><li>– Schlankes, ergonomisch geformtes Gehäuse, welches perfekt in der Hand liegt</li><li>– Kurze Bauform, perfekt für	beengte Arbeitsbereiche</li></ul>',
							en: '<ul><li>– Slim, ergonomically designed housing that fits perfectly in the hand</li><li>– Short design, perfect for confined work areas</li></ul>',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '81%',
						y: '-6%'
					}
				]
			},
		],
		gallery: {
			imageOne: RC3202Gallery1,
			imageTwo: RC3202Gallery2,
			imageThree: RC3202Gallery3,
		}
	},
	{
		id: 'R-RC3203',
		type: 'Register',
		productId: 'RC3203',
		overline: { 
			de: 'RC3203',
			en: 'RC3203',
			it: 'RC3203',
			fr: 'RC3203',
			es: 'RC3203',
			pl: 'RC3203',
			ru: 'RC3203'
		},
		headline: { 
			de: 'Mini-Schlag-Ratschenschrauber',
			en: 'Mini impact ratchet wrench',
			it: 'X',
			fr: 'X',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		url: {
			de: 'https://www.rodcraft.com/de/8951000435.html',
			en: 'https://www.rodcraft.com/gb/8951000435.html',
			it: 'https://www.rodcraft.com/it/8951000435.html',
			fr: 'https://www.rodcraft.com/fr/8951000435.html',
			es: 'https://www.rodcraft.com/es/8951000435.html',
			pl: 'https://www.rodcraft.com/pl/8951000435.html',
			ru: 'https://www.rodcraft.com/ru/8951000435.html'
		},
		pdf: {
			de: PDFde,
			// en: PDFen,
			it: PDFit,
			fr: PDFfr,
			es: 'X',
			pl: PDFpl,
			ru: 'X'
		},
		image: RC3203Big,
		registers: [
			{
				name: {
					de: 'Produktivität',
					en: 'Productivity',
					it: 'Producttività',
					fr: 'Productivité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Leistungsstark',
							en: 'Powerful',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '223-Watt-Druckluftmotor',
							en: '223 watt air motor',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '54%',
						y: '-8%'
					},
					{
						headline: {
							de: 'Langlebig',
							en: 'Durable',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Robuste Mechanik in Metall für lange Lebensdauer',
							en: 'Robust mechanics in metal for long service life',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '21%',
						y: '36%'
					}
				]
			},
			{
				name: {
					de: 'Sicherheit',
					en: 'Safety',
					it: 'Sicurezza',
					fr: 'Sécurité',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: 'Hohe Sicherheit',
							en: 'High safety',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Inkl. Sicherheitsauslöser (Ratschen mit normalem Auslöser)',
							en: 'Incl. safety trigger (ratchets with normal trigger)',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '70%',
						y: '74%'
					}
				]
			},
			{
				name: {
					de: 'Ergonomisch',
					en: 'Ergonomics',
					it: 'Ergonomia',
					fr: 'Ergonomie',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				points: [
					{
						headline: {
							de: ' ',
							en: ' ',
							it: ' ',
							fr: ' ',
							es: ' ',
							pl: ' ',
							ru: ' '
						},
						copy: {
							de: 'Kompakter & kräftiger 3/8" Schlag-Ratschenschrauber für enge Platzverhältnisse',
							en: 'Compact & powerful 3/8" impact ratchet wrench for confined spaces',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '4%',
						y: '14%'
					},
					{
						headline: {
							de: 'Hoher Komfort',
							en: 'High comfort',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: 'Kälteisolierendes und leichtes	Komposit-Gehäuse',
							en: 'Cold-insulating and lightweight composite housing',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '53%',
						y: '-6%'
					},
					{
						headline: {
							de: 'Ergonomisch',
							en: 'Ergonomic',
							it: 'Ergonomico',
							fr: 'Ergonomique',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						copy: {
							de: '<ul><li>– Schlankes, ergonomisch geformtes Gehäuse, welches perfekt in der Hand liegt</li><li>– Kurze Bauform, perfekt für	beengte Arbeitsbereiche</li></ul>',
							en: '<ul><li>– Slim, ergonomically designed housing that fits perfectly in the hand</li><li>– Short design, perfect for confined work areas</li></ul>',
							it: 'X',
							fr: 'X',
							es: 'X',
							pl: 'X',
							ru: 'X'
						},
						x: '81%',
						y: '-5%'
					}
				]
			},
		],
		gallery: {
			imageOne: RC3203Gallery1,
			imageTwo: RC3203Gallery2,
			imageThree: RC3203Gallery3,
		}
	},
	{
		id: 10,
		type: 'Video',
		videoSrcURL: {
			de: "https://www.youtube.com/embed/-d6ZzmeQ3ww",
			en: "https://www.youtube.com/embed/0EBgG_J1ajA",
		},
		
	},
	{
		id: 5, type: 'ProductGrid',
		overline: {
			de: 'Mini-Serie',
			en: 'Mini-Series',
			it: 'Mini-Serie',
			fr: 'Mini-Serie',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		headline: {
			de: 'Produktübersicht',
			en: 'Product overview',
			it: 'Panoramica del prodotto',
			fr: 'Aperçu des produits',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		products: [
			{
				id: 'a-RC7001',
				name: {
					de: 'RC7001',
					en: 'RC7001',
					it: 'RC7001',
					fr: 'RC7001',
					es: 'RC7001',
					pl: 'RC7001',
					ru: 'RC7001'
				},
				excerpt: {
					de: 'Mini-Stabschleifer, 25.000 U/min, 116 mm',
					en: 'Mini die grinder, 25,000 rpm, 116 mm',
					fr: 'X',
					it: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC7001,
				url: {
					de: 'https://www.rodcraft.com/de/8951000428.html',
					en: 'https://www.rodcraft.com/gb/8951000428.html',
					it: 'https://www.rodcraft.com/it/8951000428.html',
					fr: 'https://www.rodcraft.com/de/8951000428.html',
					es: 'https://www.rodcraft.com/es/8951000428.html',
					pl: 'https://www.rodcraft.com/pl/8951000428.html',
					ru: 'https://www.rodcraft.com/ru/8951000428.html'
				}
			},
			{
				id: 'a-RC7102',
				name: {
					de: 'RC7102',
					en: 'RC7102',
					it: 'RC7102',
					fr: 'RC7102',
					es: 'RC7102',
					pl: 'RC7102',
					ru: 'RC7102'
				},
				excerpt: {
					de: 'Mini-Stabschleifer, 18.000 U/min, 127 mm',
					en: 'Mini die grinder, 18,000 rpm, 127 mm',
					fr: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X',
					it: 'X',
				},
				image: RC7102,
				url: {
					de: 'https://www.rodcraft.com/de/8951000429.html',
					en: 'https://www.rodcraft.com/gb/8951000429.html',
					it: 'https://www.rodcraft.com/it/8951000429.html',
					fr: 'https://www.rodcraft.com/de/8951000429.html',
					es: 'https://www.rodcraft.com/es/8951000429.html',
					pl: 'https://www.rodcraft.com/pl/8951000429.html',
					ru: 'https://www.rodcraft.com/ru/8951000429.html'
				}
			},
			{
				id: 'a-RC7103',
				name: {
					de: 'RC7103',
					en: 'RC7103',
					it: 'RC7103',
					fr: 'RC7103',
					es: 'RC7103',
					pl: 'RC7103',
					ru: 'RC7103'
				},
				excerpt: {
					de: 'Mini-Winkelschleifer, 18.000 U/min, 129 mm',
					en: 'Mini angle grinder, 18,000 rpm, 129 mm',
					fr: 'X',
					it: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC7103,
				url: {
					de: 'https://www.rodcraft.com/de/8951000430.html',
					en: 'https://www.rodcraft.com/gb/8951000430.html',
					it: 'https://www.rodcraft.com/it/8951000430.html',
					fr: 'https://www.rodcraft.com/de/8951000430.html',
					es: 'https://www.rodcraft.com/es/8951000430.html',
					pl: 'https://www.rodcraft.com/pl/8951000430.html',
					ru: 'https://www.rodcraft.com/ru/8951000430.html'
				}
			},
			{
				id: 'a-RC7601',
				name: {
					de: 'RC7601',
					en: 'RC7601',
					it: 'RC7601',
					fr: 'RC7601',
					es: 'RC7601',
					pl: 'RC7601',
					ru: 'RC7601'
				},
				excerpt: {
					de: 'Mini Tellerschleifer, 18.000 U/min, 128 mm',
					en: 'Mini disc sander, 18,000 rpm, 128 mm',
					fr: 'X',
					it: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC7601,
				url: {
					de: 'https://www.rodcraft.com/de/8951000431.html',
					en: 'https://www.rodcraft.com/gb/8951000431.html',
					it: 'https://www.rodcraft.com/it/8951000431.html',
					fr: 'https://www.rodcraft.com/de/8951000431.html',
					es: 'https://www.rodcraft.com/es/8951000431.html',
					pl: 'https://www.rodcraft.com/pl/8951000431.html',
					ru: 'https://www.rodcraft.com/ru/8951000431.html'
				}
			},
			{
				id: 'a-RC7602',
				name: {
					de: 'RC7602',
					en: 'RC7602',
					it: 'RC7602',
					fr: 'RC7602',
					es: 'RC7602',
					pl: 'RC7602',
					ru: 'RC7602'
				},
				excerpt: {
					de: 'Mini Polierer, 4.500 U/min, 152 mm',
					en: 'Mini polisher, 4,500 rpm, 152 mm',
					fr: 'X',
					it: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC7602,
				url: {
					de: 'https://www.rodcraft.com/de/8951000432.html',
					en: 'https://www.rodcraft.com/gb/8951000432.html',
					it: 'https://www.rodcraft.com/it/8951000432.html',
					fr: 'https://www.rodcraft.com/de/8951000432.html',
					es: 'https://www.rodcraft.com/es/8951000432.html',
					pl: 'https://www.rodcraft.com/pl/8951000432.html',
					ru: 'https://www.rodcraft.com/ru/8951000432.html'
				}
			},
			{
				id: 'a-RC3201',
				name: {
					de: 'RC3201',
					en: 'RC3201',
					it: 'RC3201',
					fr: 'RC3201',
					es: 'RC3201',
					pl: 'RC3201',
					ru: 'RC3201'
				},
				excerpt: {
					de: 'Mini-3/8“ Ratschen-schrauber, 300 U/min, 135 mm',
					en: 'Mini 3/8" ratchet wrench, 300 rpm, 135 mm',
					fr: 'X',
					it: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC3201,
				url: {
					de: 'https://www.rodcraft.com/de/8951000433.html',
					en: 'https://www.rodcraft.com/gb/8951000433.html',
					it: 'https://www.rodcraft.com/it/8951000433.html',
					fr: 'https://www.rodcraft.com/de/8951000433.html',
					es: 'https://www.rodcraft.com/es/8951000433.html',
					pl: 'https://www.rodcraft.com/pl/8951000433.html',
					ru: 'https://www.rodcraft.com/ru/8951000433.html'
				}
			},
			{
				id: 'a-RC3202',
				name: {
					de: 'RC3202',
					en: 'RC3202',
					it: 'RC3202',
					fr: 'RC3202',
					es: 'RC3203',
					pl: 'RC3203',
					ru: 'RC3203'
				},
				excerpt: {
					de: 'Mini-Multifunktions-Ratschenschrauber,	400 U/min, 160 mm',
					en: 'Mini multifunction ratchet wrench, 400 rpm, 160 mm',
					fr: 'X',
					it: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC3202,
				url: {
					de: 'https://www.rodcraft.com/de/8951000434.html',
					en: 'https://www.rodcraft.com/gb/8951000434.html',
					it: 'https://www.rodcraft.com/it/8951000434.html',
					fr: 'https://www.rodcraft.com/de/8951000434.html',
					es: 'https://www.rodcraft.com/es/8951000434.html',
					pl: 'https://www.rodcraft.com/pl/8951000434.html',
					ru: 'https://www.rodcraft.com/ru/8951000434.html'
				}
			},
			{
				id: 'a-RC3203',
				name: {
					de: 'RC3203',
					en: 'RC3203',
					it: 'RC3203',
					fr: 'RC3203',
					es: 'RC3203',
					pl: 'RC3203',
					ru: 'RC3203'
				},
				excerpt: {
					de: 'Mini Schlag-Ratschenschrauber, 600 U/min, 158 mm',
					en: 'Mini impact ratchet wrench, 600 rpm, 158 mm',
					fr: 'X',
					it: 'X',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				image: RC3203,
				url: {
					de: 'https://www.rodcraft.com/de/8951000435.html',
					en: 'https://www.rodcraft.com/gb/8951000435.html',
					it: 'https://www.rodcraft.com/it/8951000435.html',
					fr: 'https://www.rodcraft.com/de/8951000435.html',
					es: 'https://www.rodcraft.com/es/8951000435.html',
					pl: 'https://www.rodcraft.com/pl/8951000435.html',
					ru: 'https://www.rodcraft.com/ru/8951000435.html'
				}
			}
		]
	},
	{
		id: 6,
		type: 'ContactForm',
		success: {
			de: 'Formular erfolgreich versendet',
			en: 'Form successfully sent',
			fr: 'Formulaire envoyé avec succès',
			it: 'Modulo inviato con successo',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		overline: {
			de: 'Kontakt',
			en: 'Contact',
			fr: 'Contact',
			it: 'Contatto',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		headline: {
			de: 'Interessiert? <br> Schreiben Sie uns!',
			en: 'INTERESTED? <br> CONTACT US!',
			fr: 'INTÉRESSÉ? <br> CONTACTEZ-NOUS !',
			it: 'INTERESSATO? <br> CONTATTACI!',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		notice: {
			de: '* Alle mit einem Sternchen markierten Felder sind Pflichtfelder',
			en: '* All fields marked with an asterisk are mandatory.',
			fr: "* Tous les champs marqués d'un astérisque sont obligatoires.",
			it: '* Tutti i campi contrassegnati da un asterisco sono obbligatori.',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		button: {
			de: 'Absenden',
			en: 'Send',
			it: 'Invia',
			fr: 'Envoi',
			es: 'X',
			pl: 'X',
			ru: 'X'
		},
		fields: [
			{
				id: 'firstname',
				required: true,
				type: 'text',

				label: {
					de: 'Vorname*',
					en: 'First name*',
					fr: 'prénom*',
					it: 'Nome*',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				span: '6'
			},
			{
				id: 'lastname',
				required: true,
				type: 'text',
				label: {
					de: 'Name*',
					en: 'Last name*',
					fr: 'Nom de famille*',
					it: 'Cognome*',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				span: '6'
			},
			{
				id: 'mail',
				required: true,
				type: 'text',
				label: {
					de: 'E-Mail*',
					en: 'E-Mail*',
					fr: 'E-Mail*',
					it: 'E-Mail*',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				span: '6'
			},
			{
				id: 'phone',
				required: false,
				type: 'text',
				label: {
					de: 'Telefon',
					en: 'Phone',
					fr: 'Téléphone',
					it: 'Telefono',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				span: '6'
			},
			{
				id: 'post',
				required: false,
				type: 'text',
				label: {
					de: 'PLZ*',
					en: 'ZIP code',
					fr: 'Code postal',
					it: 'Codice postale',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				span: '6'
			},
			{
				id: 'country',
				required: false,
				type: 'text',
				label: {
					de: 'Land',
					en: 'Country',
					fr: 'Pays',
					it: 'Paese',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				span: '6'
			},
			{
				id: 'company',
				required: true,
				type: 'text',
				label: {
					de: 'Unternehmen (Firmenname)*',
					en: 'Company (company name)*',
					fr: 'Société (nom de la société)*',
					it: "Azienda (nome dell'azienda)*",
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				span: '6'
			},
			{
				id: 'comment',
				required: true,
				type: 'textarea',
				label: {
					de: 'Kommentar*',
					en: 'Comment*',
					fr: 'Commentaire*',
					it: 'Commento*',
					es: 'X',
					pl: 'X',
					ru: 'X'
				},
				span: '12'
			},
		]
	}
]