
import React from "react"
import styled from "styled-components";

import { Container } from "../styled/Container";
import theme from "../styled/Theme";
import { useSelector } from "react-redux";



const StyledContainer = styled( Container )`
  max-width: calc(1313px - 1rem);
  @media (min-width: ${theme.breakpoints.sm}) {
    margin-top: 69px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    margin-top: 68px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    margin-top: 69px
  }

  @media (min-width: ${theme.breakpoints.sm}) {
    padding: 0 32px
  }
  @media (min-width: ${theme.breakpoints.md}) {
    padding: 0 32px
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    padding: 0 1rem
  }
  iframe {
      width: 100%;
      height: calc((100vw - 64px) / 16 * 9);
      @media (min-width: ${theme.breakpoints.xl}) {
        height: calc(1297px / 16 * 9);
      }
  }
`


const Video = ({ videoSrcURL, videoTitle, languages, ...props }) =>
{

    const { lang } = useSelector(state => state.language)
    return (
        <div>
            {videoSrcURL[lang] && 
            <StyledContainer>
                <div className="video">
                <iframe
                    src={videoSrcURL[lang]}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                />
                </div>
            </StyledContainer>
            }
        </div>
      )
}

export default Video